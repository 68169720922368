import styled from 'styled-components'
import primitives from '@veneer/primitives'

export const Container = styled.div`
  font-family: ${primitives.typography.family0} !important;
  .showMenus ul {
    transition: height 0.5s ease;
    height: 250px;
    opacity: 1;
    overflow: visible;
    visibility: inherit;
  }
`
export const ModalFooterBtnGrp = styled.div`
  display: flex;
  justify-content: flex-end;
`
export const Header = styled.div`
  width: 260px !important;
  nav {
    padding: 18px 18px 5px 18px;
  }
`
export const HeaderIcon = styled.div`
  align-items: center;
  display: inline-flex;
  height: 24px;
  justify-content: center;
  margin-right: ${primitives.layout.size2}px !important;
  width: 24px;
`
export const HeaderTitleText = styled.div`
  display: inline-block;
  flex-grow: 1;
  overflow: hidden;
  width: 160px;
  text-overflow: ellipsis;
  white-space: nowrap;
  a {
    text-decoration: underline;
    cursor: pointer;
  }
`
export const SideMenuList = styled.ul`
  padding-left: 30px !important;
  padding-right: ${primitives.layout.size1}px !important;
`
export const SideMenuListItem = styled.li`
  white-space: nowrap;
  border-left: 2px solid rgba(88, 88, 88, 0.05);
  font-size: ${primitives.typography.size2} !important;
  line-height: ${primitives.typography.lineHeight3} !important;
  letter-spacing: 0.32px;
  padding: 4px 0px 4px 18px;
  position: relative;
  a {
    align-items: flex-end;
    box-sizing: border-box;
    color: rgb(64, 64, 64);
    cursor: pointer;
    display: flex;
    transition:
      color 300ms ease 0s,
      background-color 300ms ease 0s,
      border-color 300ms ease 0s;
    user-select: none;
    :hover {
      text-decoration: none;
    }
  }
  a[data-selected='true'] {
    color: rgb(2, 120, 171);
  }
  a[data-selected='true']::before {
    background-color: rgb(2, 120, 171);
    bottom: 0px;
    content: ' ';
    height: 24px;
    left: -2px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
  }
  .disabled {
    color: rgb(173, 173, 173);
    position: relative;
    transition: color 150ms ease-in-out 0s;
  }
  .isVisible {
    display: none;
  }
`
export const SelectedCustomer = styled.span`
  font-weight: 500;
  font-size: ${primitives.typography.size3} !important;
  margin-left: 5px;
`
export const CurrentlySelectedText = styled.div`
  font-size: ${primitives.typography.size0} !important;
  margin-bottom: ${primitives.layout.size4}px !important;
`
export const CustomersListBox = styled.div`
  border: 1px solid rgba(33, 33, 33, 0.05);
  border-radius: ${primitives.layout.cornerRadius2}px;
  margin: 10px 0;
  button {
    width: 100%;
    border-radius: 0;
    align-items: start;
    justify-content: start;
    color: #212121;
    :hover {
      color: #212121;
    }
  }
  button.selectedCustomer {
    background: rgba(2, 122, 174, 0.1) !important;
  }
  button:first-child.selectedCustomer,
  button:first-child:hover {
    border-radius: ${primitives.layout.cornerRadius2}px
      ${primitives.layout.cornerRadius2}px 0 0;
  }
`
export const NoItemsBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 260px;
  flex-direction: column;
  line-height: ${primitives.typography.lineHeight2} !important;
  svg {
    background: rgba(33, 33, 33, 0.1);
    border-radius: 50%;
    height: 36px;
    width: 36px;
    padding: 6px;
    margin-bottom: ${primitives.layout.size2}px !important;
  }
`
export const StyledLoader = styled.div`
  width: 100%;
  height: 260px;
  display: flex;
  > div:first-child {
    width: 24px;
    height: 24px;
    margin: auto;
  }
`
