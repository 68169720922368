import menuItemsJson from '../menuItems/menuItems.json'
export function menuItemsNavigation(t) {
  const menuItems = []
  menuItemsJson.forEach((item) => {
    menuItems.push({
      label: t(item.tranlationKey, item.label),
      id: item.id,
      link: item.link,
      dataTestId: item.dataTestId
    })
  })
  return menuItems
}
