import { TenantListData } from 'src/interfaces/customerSelectorPopupInterface'
import { CustomerSelectorPopUpProps } from '../components/CustomerSelectorPopUp/types'

let localprops: CustomerSelectorPopUpProps = {
  language: 'en',
  country: 'US',
  tenantHandlerInterface: ''
}

export const setProps = (props) => {
  localprops = props
}

export const getProps = (): CustomerSelectorPopUpProps => {
  return localprops
}

export const filter = (
  tenantListData: Array<TenantListData>,
  searchItem: string
) => {
  if (searchItem.length === 0) {
    return tenantListData
  }
  const filterdata = tenantListData.filter((tenant) => {
    if (searchItem.length != 0) {
      return tenant.name.toLowerCase().includes(searchItem.toLowerCase())
    } else {
      return tenant
    }
  })
  return filterdata
}
