import { getProps } from './commonMethods'
export const getTenantsList = async (page) => {
  const props = getProps()
  const tenantsList = await props.tenantHandlerInterface.getPaginatedTenantList(
    {
      authContext: 'subtenant',
      page: page,
      paginationSize: 100
    }
  )
  return tenantsList
}
export const getSelectedTenant = async () => {
  const props = getProps()
  const selectedTenantId =
    await props.tenantHandlerInterface.getTenantId('subtenant')
  const selectedTenantData = await props.tenantHandlerInterface.getTenantData({
    authContext: 'subtenant'
  })
  const selectedTenant =
    selectedTenantData?.id === selectedTenantId ? selectedTenantData : undefined
  return selectedTenant
}
export const setSelectedTenantToShell = async (tenantId: string) => {
  const props = getProps()
  const setTenant = await props.tenantHandlerInterface.setTenant({
    authContext: 'subtenant',
    tenantId: tenantId
  })
  return setTenant
}

export const getTenantById = async (tenantId: string) => {
  const props = getProps()
  const tenantByIdDetails = await props.tenantHandlerInterface.getTenantById({
    authContext: 'subtenant',
    tenantId: tenantId
  })
  return tenantByIdDetails
}
