import React, { useCallback, useEffect, useState } from 'react'
import useShellRootContext from '../../contexts/ShellRoot/useShellRootContext'
import { menuItemsNavigation } from '../../../src/utils/menuItems'
import {
  IconBuilding,
  IconWarningAlt,
  Search,
  Scrollbar,
  Tabs,
  Tooltip,
  Button,
  ProgressIndicator,
  Modal
} from '@veneer/core'
import {
  CurrentlySelectedText,
  CustomersListBox,
  ModalFooterBtnGrp,
  NoItemsBox,
  SelectedCustomer,
  Header,
  HeaderIcon,
  HeaderTitleText,
  SideMenuList,
  SideMenuListItem,
  Container,
  StyledLoader
} from './styles'
import { filter, setProps } from '../../utils/commonMethods'
import {
  getTenantsList,
  getSelectedTenant,
  getTenantById,
  setSelectedTenantToShell
} from '../../utils/api'
import { TenantListData } from '../../interfaces/customerSelectorPopupInterface'
import * as T from './types'

const CustomerSelectorPopUp = ({ ...props }: T.CustomerSelectorPopUpProps) => {
  setProps(props)
  const { t = (key, defaultValue) => defaultValue || key, interfaces } =
    useShellRootContext?.() || {}
  const navigation = interfaces?.v1?.navigation
  const featureFlags = interfaces?.v1?.featureFlags
  const [open, setOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [tenantsListData, setTenantsListData] = useState<TenantListData[]>([])
  const [tenantsList, setTenantsList] = useState<TenantListData[]>([])
  const [totalCustomers, setTotalCustomers] = useState<number>()
  const [currentSelectedTenant, setCurrentSelectedTenant] = useState<string>('')
  const [searchValue, setSearchValue] = useState<string>('')
  const [currentSelectedTenantId, setCurrentSelectedTenantId] =
    useState<string>('')
  const [selectedTenant, setSelectedTenant] = useState<string>('')
  const [selectedTenantId, setSelectedTenantId] = useState<string>('')
  const [tenantsArrayLength, setTenantsArrayLength] = useState<number>(0)
  const [tempTenantsArray, setTempTenantsArray] = useState<TenantListData[]>([])
  const [isLinkVisible, setIsLinkVisible] = useState<boolean>(false)

  let page = 0
  let totalPages = 0
  const tenantsArray: TenantListData[] = []
  const sliceStart = 0
  let sliceEnd = 100
  const FF_CLIENT_ECP = 'ecp'
  const FF_KEY_ECP_FLEET_PROXY = 'ecp-fleet-proxy'
  /* istanbul ignore next */
  const retriveTenants = async () => {
    try {
      setIsLoading(true)
      const response = await getTenantsList(page)
      totalPages = response.totalPages
      tenantsArray.push(...response.tenants)
      if (props.mockProps) {
        totalPages = 2
      }
      if (page < totalPages - 1) {
        page = page + 1
        retriveTenants()
      } else {
        setIsLoading(false)
        setTenantsArrayLength(tenantsArray.length)
        setTempTenantsArray(tenantsArray)
        setTenantsList(
          [...tenantsArray]
            .sort((a, b) =>
              a.name.localeCompare(b.name, undefined, { numeric: true })
            )
            .slice(sliceStart, sliceEnd)
        )
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const cListBox: any =
          document.querySelector('#customer-list-box').childNodes[0]
        cListBox.addEventListener('scroll', async () => {
          if (
            cListBox.scrollTop + cListBox.clientHeight ===
              cListBox.scrollHeight &&
            tenantsList.length < tenantsArray.length
          ) {
            sliceEnd = sliceEnd + 100
            setTenantsList(
              [...tenantsArray]
                .sort((a, b) =>
                  a.name.localeCompare(b.name, undefined, { numeric: true })
                )
                .slice(sliceStart, sliceEnd)
            )
          } else {
            // Do nothing
          }
        })
      }
    } catch (e) {
      console.log(e)
    }
  }

  const retriveSelectedTenant = async () => {
    try {
      const response = await getSelectedTenant()
      setSelectedTenant(response.name)
      setSelectedTenantId(response.id)
    } catch (e) {
      console.log(e)
    }
  }
  const retriveTenantById = async (tenantId: string) => {
    try {
      const response = await getTenantById(tenantId)
      setCurrentSelectedTenant(response.name)
      setCurrentSelectedTenantId(response.id)
    } catch (e) {
      console.log(e)
    }
  }
  const setSelectedTenantData = async (tenantId: string) => {
    try {
      await setSelectedTenantToShell(tenantId).then(() => {
        window.location.reload()
      })
    } catch (e) {
      console.log(e)
    }
  }
  /* istanbul ignore next */
  const handleClick = (manuLink: string) => {
    if (selectedTenant) {
      return navigation?.push(`${manuLink}`)
    } else {
      return
    }
  }
  /* istanbul ignore next */
  const renderSideMenuItems = () => {
    return (
      <SideMenuList>
        {menuItemsNavigation(t).map((menuItem) => (
          <SideMenuListItem
            key={menuItem.id}
            data-testid={menuItem.dataTestId}
          >
            {selectedTenant && (
              <a
                onClick={() => handleClick(`${menuItem.link}`)}
                data-selected={navigation.location.pathname === menuItem.link}
                className={
                  !isLinkVisible && menuItem.label === 'Proxies'
                    ? 'isVisible'
                    : ''
                }
              >
                {menuItem.label}
              </a>
            )}

            {!selectedTenant && (
              <span
                className={
                  !isLinkVisible && menuItem.label === 'Proxies'
                    ? 'isVisible'
                    : 'disabled'
                }
              >
                {menuItem.label}
              </span>
            )}
          </SideMenuListItem>
        ))}
      </SideMenuList>
    )
  }
  /* istanbul ignore next */
  useEffect(() => {
    if (featureFlags?.getClient) {
      featureFlags.getClient(FF_CLIENT_ECP).then((client) => {
        if (client) {
          client
            .getFeatureFlag({
              key: FF_KEY_ECP_FLEET_PROXY,
              defaultValue: false
            })
            .then((flag) => {
              setIsLinkVisible(flag as boolean)
            })
        }
      })
    }
  }, [featureFlags])

  useEffect(() => {
    retriveTenants()
    retriveSelectedTenant()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleFilterData = (searchValue: string) => {
    if (props.mockProps) {
      searchValue = '20'
    }
    if (searchValue.length) {
      setIsLoading(true)
      const filteredData = filter(tempTenantsArray, searchValue).sort((a, b) =>
        a.name.localeCompare(b.name, undefined, { numeric: true })
      )
      setTenantsListData(filteredData)
      setTotalCustomers(filteredData.length)
      setTimeout(() => {
        setIsLoading(false)
      }, 1000)
    } else {
      const filteredData = filter(tenantsList, searchValue)
      setTenantsListData(filteredData)
      setTotalCustomers(filteredData.length)
    }
  }

  useEffect(() => {
    handleFilterData(searchValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantsList, searchValue, tempTenantsArray])

  const onChange = (value: React.SetStateAction<string>) => {
    if (value.length === 0) {
      setSearchValue(value)
      retriveTenants()
    } else {
      setSearchValue(value)
    }
  }

  const onClickChoose = useCallback(() => {
    setIsLoading(true)
    setSelectedTenantData(currentSelectedTenantId)
  }, [currentSelectedTenantId])

  const onClickCancel = useCallback(() => {
    setOpen(false)
    setCurrentSelectedTenant('')
    setCurrentSelectedTenantId('')
  }, [])

  return (
    <Container>
      <Modal
        align="start"
        alignFooter="end"
        id="modal-id"
        show={open}
        title={t('customer-selector-popup.chooseCustomer', 'Choose Customer')}
        data-testid="choose-customer-modal"
        footer={
          !isLoading && (
            <ModalFooterBtnGrp className="vn-button-group--responsive">
              <Button
                appearance="primary"
                data-testid="choose-button"
                loading={isLoading}
                disabled={!currentSelectedTenantId}
                onClick={onClickChoose}
              >
                {t('customer-selector-popup.choose', 'Choose')}
              </Button>
              <Button
                appearance="secondary"
                data-testid="cancel-button"
                onClick={onClickCancel}
              >
                {t('customer-selector-popup.cancel', 'Cancel')}
              </Button>
            </ModalFooterBtnGrp>
          )
        }
      >
        <CurrentlySelectedText>
          {t(
            'customer-selector-popup.currentlySelected',
            'Currently Selected:'
          )}
          <SelectedCustomer data-testid="currently-selected-customer">
            {currentSelectedTenant
              ? currentSelectedTenant
              : selectedTenant
                ? selectedTenant
                : ' - '}
          </SelectedCustomer>
        </CurrentlySelectedText>
        <Search
          data-testid="search-customer"
          placeholder={t('customer-selector-popup.search', 'Search')}
          value={searchValue}
          onChange={onChange}
          content={undefined}
          nonce={undefined}
          rel={undefined}
          rev={undefined}
        />
        <Tabs
          controlId="default"
          mode="extended"
          data-testid="choose-from-customer-list-tab"
          selectedTabId={'0'}
          tabs={[
            {
              id: '0',
              label:
                `${t('customer-selector-popup.all', 'All')}` +
                ' (' +
                `${
                  searchValue.length === 0 && tenantsArrayLength
                    ? tenantsArrayLength
                    : totalCustomers
                }` +
                ')',
              content: (
                <>
                  <CustomersListBox
                    data-testid="customer-list"
                    id="customer-list-box"
                  >
                    {isLoading ? (
                      <StyledLoader>
                        <ProgressIndicator />
                      </StyledLoader>
                    ) : (
                      <Scrollbar
                        css={{
                          height: '260px',
                          overflow: 'auto'
                        }}
                      >
                        {tenantsListData &&
                          tenantsListData.map((customer) => (
                            <Button
                              appearance="ghost"
                              key={customer.id}
                              value={customer.id}
                              data-testid="select-customer-button"
                              className={
                                currentSelectedTenantId === customer.id
                                  ? 'selectedCustomer'
                                  : !currentSelectedTenantId
                                    ? selectedTenantId === customer.id
                                      ? 'selectedCustomer'
                                      : ''
                                    : ''
                              }
                              onClick={(e) => {
                                retriveTenantById(e.currentTarget.value)
                              }}
                            >
                              {customer.name}
                            </Button>
                          ))}
                        {tenantsListData.length === 0 && (
                          <NoItemsBox data-testid="no-customers-found">
                            <IconWarningAlt />
                            <p>
                              {t(
                                'customer-selector-popup.noResults',
                                'No Results Found.'
                              )}
                            </p>
                            <p>
                              {t(
                                'customer-selector-popup.adjustSearch',
                                'Adjust your search keywords.'
                              )}
                            </p>
                          </NoItemsBox>
                        )}
                      </Scrollbar>
                    )}
                  </CustomersListBox>
                </>
              )
            }
          ]}
          type="online"
        />
      </Modal>
      <Header>
        <nav>
          <ul>
            <li>
              <HeaderIcon data-testid="choose-customer-icon">
                <IconBuilding />
              </HeaderIcon>
              <HeaderTitleText>
                <Tooltip
                  content={
                    selectedTenant
                      ? selectedTenant
                      : `${t(
                          'customer-selector-popup.chooseCustomer',
                          'Choose Customer'
                        )}`
                  }
                  id="tooltip"
                  placement="bottom"
                >
                  <a
                    onClick={() => setOpen(true)}
                    data-testid="choose-customer"
                  >
                    {selectedTenant
                      ? selectedTenant
                      : `${t(
                          'customer-selector-popup.chooseCustomer',
                          'Choose Customer'
                        )}`}
                  </a>
                </Tooltip>
              </HeaderTitleText>
            </li>
          </ul>
        </nav>
      </Header>
      <div className="showMenus">{renderSideMenuItems()}</div>
    </Container>
  )
}

export default CustomerSelectorPopUp
